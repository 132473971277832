import type { FC, KeyboardEvent, FormEvent } from 'react';
import type { MenuLink } from '@src/interfaces';
import React, { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import SvgImage from '@src/components/SvgImage';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import { trackEvent } from '@src/utils/et';
import type { Models } from '@data/et-web-api';
import styles from './styles.module.scss';

type CustomEventTarget = EventTarget & {
  query: HTMLInputElement;
};

type OverlayProps = {
  links: MenuLink[];
  socials: Record<string, string>;
  onClose(): void;
};

const Overlay: FC<OverlayProps> = ({
  links,
  socials,
  onClose,
}) => {
  const router = useRouter();
  const firstFocusable = useRef<HTMLButtonElement>(null);
  const lastFocusable = useRef<HTMLAnchorElement>(null);

  useEffect(() => {
    if (firstFocusable.current) {
      firstFocusable.current.focus();
    }
  }, []);

  const handleKeyboard = (event: KeyboardEvent<HTMLImageElement>) => {
    if (event.key === 'Escape') {
      onClose();
    } else if (event.key === 'Tab') {
      if (event.shiftKey && document.activeElement === firstFocusable.current) {
        event.preventDefault();
        lastFocusable.current?.focus();
      } else if (!event.shiftKey && document.activeElement === lastFocusable.current) {
        event.preventDefault();
        firstFocusable.current?.focus();
      }
    }
  };

  useEffect(() => {
    router.events.on('routeChangeStart', onClose);

    return () => {
      router.events.off('routeChangeStart', onClose);
    };
  }, [router, onClose]);

  const handleSearch = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const query = (event.target as CustomEventTarget).query.value;

    if (query) {
      const eventName: Models.SearchClick['eventName'] = 'Search Click';
      const eventPayload: Models.SearchClick['eventPayload'] = {
        searchTerm: query,
      }
      trackEvent(eventName, eventPayload);
      router.push({ pathname: '/suche', query: { q: query } });
    }
  };

  const onClickSocial = (social: string, url: string) => {
    const eventName: Models.LinkoutClick['eventName'] = 'Linkout Click';
    const eventPayload: Models.LinkoutClick['eventPayload'] = {
      linkoutLabel: `${social} link click`,
      targetUrl: url,
    }
    trackEvent(eventName, eventPayload);
  };

  const onClickLink = (link: string, title: string) => {
    const eventPayload: Models.NavigationClick["eventPayload"] = {
      targetScreenName: 'Home',
      navigationLabel: title,
      navigationLevel: '1',
      targetUrl: stripUrlPlaceholder(link),
    };
    trackEvent('Navigation Click', eventPayload);
  };

  return (
    <div className={styles.overlay} onKeyDown={handleKeyboard}>
      <button
        ref={firstFocusable}
        aria-expanded
        aria-label="Menu"
        className={styles.close}
        onClick={onClose}
        type="button"
      >
        <SvgImage reference="close" title="Menu" />
      </button>

      <form className={styles.search} onSubmit={handleSearch}>
        <SvgImage
          className={styles.searchIcon}
          reference="search"
          title="Suche"
        />
        <label className="sr-only" htmlFor="searchId">Suche</label>
        <input
          className={styles.searchInput}
          id="searchId"
          name="query"
          placeholder="Suche"
          required
          type="text"
        />
        <button className="sr-only" type="submit">
          Suche
        </button>
      </form>

      <ul className={styles.links}>
        {links.map(({ id, link, title, attributes }) => (
          <li key={id} className={styles.item}>
            <Link as={stripUrlPlaceholder(link)} href="/">
              <a {...attributes} className={styles.anchor} onClick={() => onClickLink(link, title)}>
                {title}
              </a>
            </Link>
          </li>
        ))}
      </ul>

      <ul className={styles.socials}>
        {Object.entries(socials).map(([social, url]) => (
          <li key={social} className={styles.social}>
            <a
              ref={lastFocusable}
              aria-label="Social"
              className={styles.socialAnchor}
              data-social-provider={social}
              href={url}
              onClick={() => onClickSocial(social, url)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <SvgImage reference={social} title={social} />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Overlay;
