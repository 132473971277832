import type { FC } from 'react';
import type { Settings } from 'react-slick';
import type { Teaser } from '@src/interfaces';
import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import TeaserComponent from '@src/components/TeaserComponent';
import ComponentTitle from '@src/components/ComponentTitle';
import SvgImage from '@src/components/SvgImage';
import onClickVideoTeaser from "@src/hooks/onClickVideoTeaser";
import onClickTeaser from "@src/hooks/onClickTeaser";
import styles from './styles.module.scss';

type StackedTeaserCarouselProps = {
  title?: string | null;
  link?: string | null;
  teasers: Teaser[];
};

const StackedTeaserCarousel: FC<StackedTeaserCarouselProps> = ({
  title,
  link,
  teasers,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(1);
  const slider = useRef<Slider>(null);

  const slickSettings: Settings = {
    accessibility: true,
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  const slidePrev = () => {
    if (currentSlideIndex > 1) {
      setCurrentSlideIndex(currentSlideIndex - 1);
      slider.current?.slickPrev();
    }
  };

  const slideNext = () => {
    if (currentSlideIndex < teasers.length) {
      setCurrentSlideIndex(currentSlideIndex + 1);
      slider.current?.slickNext();
    }
  };

  return (
    <div className={styles.container}>
      {title ? <h3 className="component-title teaser-list-title">
        <ComponentTitle link={link} skipWrapper title={title} />
      </h3> : null}
      <div aria-live="polite" className="sr-only">
        {`Slide ${currentSlideIndex} of ${teasers.length}`}
      </div>
      {!isExpanded ? (
        <div className={styles.sliderContainer}>
          <button
            aria-label="Vorherige"
            className={styles.controlLeft}
            disabled={currentSlideIndex === 1}
            onClick={slidePrev}
            type="button"
          >
            <SvgImage reference="arrow-left-white" title="Vorherige" />
          </button>
          <Slider
            ref={slider}
            className={styles.slider}
            {...slickSettings}
          >
            {teasers.map((teaser, index) => (
              <TeaserComponent
                key={teaser.id}
                className={styles.teaser}
                disablesFocus={index !== currentSlideIndex - 1}
                onClick={() => teaser.videoDuration ? onClickVideoTeaser(teaser, title, teasers.length, index) : onClickTeaser(teaser, title, teasers.length, index)}
                size="medium"
                {...teaser}
              />
            ))}
          </Slider>
          <button
            aria-label="Nächste"
            className={styles.controlRight}
            disabled={currentSlideIndex === teasers.length}
            onClick={slideNext}
            type="button"
          >
            <SvgImage reference="arrow-right-white" title="Nächste" />
          </button>
        </div>
      ) : (
        <div className={styles.slidesContainer}>
          {teasers.map(teaser => (
            <TeaserComponent
              key={teaser.id}
              className={styles.teaser}
              {...teaser}
            />
          ))}
        </div>
      )}

      <button
        aria-expanded={isExpanded}
        className={styles.expand}
        onClick={() => setIsExpanded(!isExpanded)}
        type="button"
      >
        <SvgImage ariaHidden reference="expand-carousel" />
        {isExpanded
          ? 'Weniger anzeigen'
          : 'Mehr anzeigen'}
      </button>
    </div>
  );
};

export default StackedTeaserCarousel;
