import React from 'react';
import { SingleTeaserComponent } from '@src/interfaces';
import TeaserComponent from '@src/components/TeaserComponent';
import ContentTeaser from '@src/components/ContentTeaser';
import ComponentTitle from '@src/components/ComponentTitle';
import onClickTeaser from "@src/hooks/onClickTeaser";
import onClickVideoTeaser from '@src/hooks/onClickVideoTeaser';

const SingleTeaser = ({ id, title, link, teasers, withDescription }: SingleTeaserComponent) => {
  if (!teasers.length) return null;

  const teaser = teasers[0];
  return (
    <div
      className={`single-teaser${title ? ' has-title' : ''}`}
      data-vr-zone={`SingleTeaser ${title ?? id}`}
    >
      <ComponentTitle link={link} title={title} />

      {withDescription ? <ContentTeaser contentBox="SingleTeaser #1" onClick={() => onClickTeaser(teaser, title, teasers.length, 1)} {...teaser} /> : null}

      {!withDescription && (
        <TeaserComponent contentBox="SingleTeaser #1" onClick={() => teaser.videoDuration ? onClickVideoTeaser(teaser, title, teasers.length, 1) : onClickTeaser(teaser, title, teasers.length, 1)} {...teaser} />
      )}
    </div>
  );
};

export default SingleTeaser;
