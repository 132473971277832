import React from 'react';
import Link from 'next/link';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';
import { SingleTeaserComponent } from '@src/interfaces';

const ComponentTitle = ({
  title,
  link,
  skipWrapper = false,
}: Pick<SingleTeaserComponent, 'title' | 'link'> & {
  skipWrapper?: boolean;
}) => {
  if (!title) return null;

  const content =
    title && link ? (
      <Link as={stripUrlPlaceholder(link)} href="/">
        <a className="teaser-anchor" title={title}>
          {title}
        </a>
      </Link>
    ) : (
      title
    );

  if (skipWrapper) return <>{content}</>;

  return <h3 className="component-title col-sm-12">{content}</h3>;
};

export default ComponentTitle;
