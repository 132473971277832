import type { Region, Section } from '@src/interfaces';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import Link from 'next/link';
import DynamicComponent from '@src/components/DynamicComponent';
import ComponentError from '@src/components/ComponentError';
import Countdown from '@src/components/Countdown/Countdown';
import stripUrlPlaceholder from '@src/utils/strip-url-placeholder';

type RegionWithWidth = Region & {
  width: string;
};

const RegionComponent = (region: RegionWithWidth) => {
  const components = region.components.map((component, componentIndex) => {
    const instance = <DynamicComponent component={component} regionWidth={region.width} />

    return (
      <ErrorBoundary
        key={`e${componentIndex}-${component.type}`}
        FallbackComponent={ComponentError}
      >
        {component.schedule
          ? <Countdown schedule={component.schedule}>{instance}</Countdown>
          : instance
        }
      </ErrorBoundary>
    );
  });

  return React.createElement(
    region.tagName ?? 'div',
    {
      className: `region region-index-${region.position - 1} col-12 col-lg-${region.width}`,
    },
    components,
  );
};

const SectionComponent = ({
  regions,
  columnWidths,
  title,
  link,
}: Section) => (
  <section className="section row">
    {title ? <h2 className="component-title section-title col-12">
      {link ? (
        <Link as={stripUrlPlaceholder(link)} href="/">
          <a className="teaser-anchor" title={title}>{title}</a>
        </Link>
        ) : title}
    </h2> : null}

    {regions.map((region, index) => (
      <RegionComponent
        key={`r${index}`}
        width={columnWidths.split('-')[region.position - 1]}
        {...region}
      />
    ))}
  </section>
);

export default SectionComponent;
