import React from 'react';
import SvgImage from '@src/components/SvgImage';
import {Models} from "@data/et-web-api";
import {trackEvent} from "@src/utils/et";

export type SocialProvider = 'facebook' | 'instagram' | 'linkedin' | 'twitter';

export type SocialUrls = {
  [k in SocialProvider]?: string;
};

export type SocialStyling = 'normal' | 'rounded';

export interface SocialIconProps {
  baseUrls: SocialUrls;
  styling?: SocialStyling | undefined;
  url?: string;
  showTitle?: boolean;
}

const onClickSocial = (social: string, url: string|undefined) => {
  const eventName: Models.LinkoutClick['eventName'] = 'Linkout Click';
  const eventPayload: Models.LinkoutClick['eventPayload'] = {
    linkoutLabel: `${social} sharing`,
    targetUrl: url || '',
  }
  trackEvent(eventName, eventPayload);
};

const SocialIcons = ({ baseUrls, url, showTitle, styling = 'normal' }: SocialIconProps) => (
  <>
    {(Object.keys(baseUrls) as Array<keyof SocialUrls>).map((key, index) => (
      <a
        key={index}
        aria-label={key}
        data-social-provider={key}
        href={`${baseUrls[key]}${url || ''}`}
        onClick={() => onClickSocial(key, baseUrls[key])}
        rel="noopener noreferrer"
        target="_blank"
      >
        <SvgImage className="social-icon" reference={styling === 'rounded' ? `${key}-round` : key} title={key} /> {showTitle ? `teilen` : ''}
      </a>
    ))}
  </>
);
export default SocialIcons;
